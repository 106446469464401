import React from "react";
import { formatPrice } from "../utils";

const AcquistaReportStep3 = ({ state, dispatch }) => {
  const { fatturazione } = state;

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "step3" });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="box-progress">
            <div className="box-progress__title">Riepilogo acquisto</div>
            <div className="box-progress__bar">
              <span className="step3"></span>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={onSubmit} className="form pb-0">
        <div className="row">
          <div className="col-12">
            <div className="box-recap">
              <div className="box-recap__head d-none d-sm-block">
                <div className="row">
                  <div className="col-12 col-sm-3">Il mio acquisto</div>
                  <div className="col-12 col-sm-3">Prezzo totale</div>
                </div>
              </div>
              <div className="box-recap__row">
                <div className="row">
                  <div className="col-12 col-sm-3">Report</div>
                  <div className="col-12 col-sm-3">
                    <span className="d-block d-md-none me-2">Prezzo totale: </span>
                    {formatPrice(15000)}
                  </div>
                </div>
              </div>
              {fatturazione.iva !== "false" && (
                <div className="box-recap__sum">
                  <div className="row">
                    <div className="col-12 col-sm-3">IVA</div>
                    <div className="col-12 col-sm-3">
                      {formatPrice(fatturazione.iva === "true" ? 15000 * 0.22 : 0)}
                    </div>
                  </div>
                </div>
              )}
              <div className="box-recap__results text-uppercase">
                <div className="row">
                  <div className="col-12 col-sm-3">Totale ordine</div>
                  <div className="col-12 col-sm-3">
                    {formatPrice(fatturazione.iva === "true" ? 15000 * 1.22 : 15000)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form__navigation">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  onClick={() => dispatch({ type: "back" })}
                  className="btn-link btn-link--small btn-link--prev"
                >
                  <span className="btn-link__text">Back</span>
                  <span className="btn-link__circle"></span>
                </button>
                <button type="submit" className="btn-link btn-link--small btn-link--next">
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">Prosegui</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AcquistaReportStep3;
