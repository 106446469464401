import React, { useEffect } from "react";
import { navigate } from "gatsby";
import useSessionReducer from "../../hooks/use-session-reducer";
import AcquistaReportLayout from "../../components/acquista-report-layout";
import AcquistaLoading from "../../components/acquista-loading";
import AcquistaReportStep1 from "../../components/acquista-report-step1";
import AcquistaReportStep2 from "../../components/acquista-report-step2";
import AcquistaReportStep3 from "../../components/acquista-report-step3";
import AcquistaReportStep4 from "../../components/acquista-report-step4";

const formStepsReducer = (state, action) => {
  const { step } = state;
  switch (action.type) {
    case "init":
      return {
        ...action.payload,
        init: true,
      };
    case "step1":
      const user = action.payload;
      return {
        ...state,
        user,
        step: 2,
      };
    case "back":
      return {
        ...state,
        step: step - 1,
      };
    case "step2":
      return {
        ...state,
        fatturazione:
          action.payload.customerType === "azienda"
            ? action.payload
            : {
                customerType: action.payload.customerType,
                iva: "true",
                fiscalCode: action.payload.fiscalCode,
                street: action.payload.street,
                number: action.payload.number,
                city: action.payload.city,
                cap: action.payload.cap,
                province: action.payload.province,
                newsletter: action.payload.newsletter,
                privacyInfo: action.payload.privacyInfo,
                privacyData: action.payload.privacyData,
              },
        step: 3,
      };
    case "step3":
      return {
        ...state,
        step: 4,
      };
    case "step4":
      return {
        ...state,
        payment: action.payload.payment,
        submit: true,
      };
    case "submitError":
      return {
        ...state,
        submitError: true,
        submit: false,
      };
    case "retry":
      return {
        ...state,
        submitError: false,
      };
    default:
      return state;
  }
};

const AcquistaReportPage = () => {
  const [state, dispatch] = useSessionReducer("iid-form-acquista-report-iic", formStepsReducer, {
    step: 1,
  });
  const { init, step, user, fatturazione, payment, submit, submitError } = state;

  useEffect(() => {
    const { user, fatturazione, payment, submit } = state;
    if (submit) {
      const checkout = async () => {
        const payload = {
          user,
          fatturazione,
          success_url: `${window.location.origin}/acquista-report-iic/grazie-dell-acquisto-card/`,
          cancel_url: window.location.href,
        };
        if (payment === "card") {
          console.log("Create checkout session: ", payload);
          try {
            const response = await fetch("/api/checkout-report-session", {
              method: "POST",
              body: JSON.stringify(payload),
            });
            if (!response.ok) {
              throw new Error(
                `Error calling /api/checkout-session: ${response.status} ${response.statusText}`
              );
            }
            const data = await response.json();
            window.location.assign(data.redirectUrl);
          } catch (err) {
            dispatch({ type: "submitError" });
            console.error("Error creating checkout session: ", err);
          }
        } else {
          try {
            const response = await fetch("/api/email-report-bonifico", {
              method: "POST",
              body: JSON.stringify(payload),
            });
            if (!response.ok) {
              throw new Error(
                `Error calling /api/email-bonifico: ${response.status} ${response.statusText}`
              );
            }
            const result = await response.json();
            console.log("result: ", result);
            navigate("/acquista-report-iic/grazie-dell-acquisto-bonifico/");
          } catch (err) {
            dispatch({ type: "submitError" });
            console.error("Error bonifico: ", err);
          }
        }
      };
      checkout();
    }
  }, [state, dispatch]);

  return (
    <AcquistaReportLayout>
      {init ? (
        submit || submitError ? (
          <AcquistaLoading error={submitError} retry={() => dispatch({ type: "retry" })} />
        ) : (
          {
            1: <AcquistaReportStep1 data={user} dispatch={dispatch} />,
            2: <AcquistaReportStep2 state={state} data={fatturazione} dispatch={dispatch} />,
            3: <AcquistaReportStep3 state={state} dispatch={dispatch} />,
            4: <AcquistaReportStep4 data={payment} dispatch={dispatch} />,
          }[step]
        )
      ) : (
        <div></div>
      )}
    </AcquistaReportLayout>
  );
};

export default AcquistaReportPage;
