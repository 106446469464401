import React, { useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

const yearOption = [{ value: 2023, label: "2023" }];

const AcquistaReportStep1 = ({ data, dispatch }) => {
  const yearRef = useRef();
  const {
    register,
    handleSubmit,
    control: controlYear,
    formState: { errors },
  } = useForm({
    defaultValues: { ...data, year: data?.year || 2023 },
    mode: "onChange",
  });

  const onSubmit = (data) => {
    console.log("data: ", data);
    dispatch({
      type: "step1",
      payload: data,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="box-progress">
            <div className="box-progress__title">Inserisci i tuoi dati</div>
            <div className="box-progress__bar">
              <span className="step1"></span>
            </div>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="form pb-0">
        <div className="row d-none">
          <div className="col-12 col-sm-6">
            <div className="form__group">
              <div className="form__placeholder-select">
                Selezionare l'anno del report d'interesse *
              </div>
              <Controller
                render={({ field: { value, onChange, ...other } }) => (
                  <Select
                    {...other}
                    options={yearOption}
                    isSearchable={false}
                    onChange={(option) => onChange(option.value)}
                    className="form__select"
                    classNamePrefix="select"
                    placeholder="Seleziona anno"
                    noOptionsMessage={() => "Nessun risultato"}
                    value={yearOption.find((option) => option.value === value)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: "#1a1b1f",
                        primary: "#333333",
                      },
                    })}
                    ref={(ref) => (yearRef.current = ref)}
                  />
                )}
                name="year"
                control={controlYear}
                onFocus={() => yearRef.current.focus()}
                rules={{ required: true }}
              />
              {errors.year ? (
                <span className="d-block form__error">
                  Selezionare l'anno del report di interesse.
                </span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="name"
                  id="name"
                  placeholder=" "
                  {...register("name", {
                    required: {
                      value: true,
                      message: "Specificare un nome.",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: "Specificare un nome (solo caratteri e/o spazi).",
                    },
                  })}
                />
                <label className="form__label" htmlFor="name">
                  Nome *
                </label>
              </div>
              {errors.name ? (
                <span className="d-block form__error">{errors.name.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="text"
                  className="form__input-text"
                  name="surname"
                  id="surname"
                  placeholder=" "
                  {...register("surname", {
                    required: {
                      value: true,
                      message: "Specificare un cognome.",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]*$/,
                      message: "Specificare un cognome (solo caratteri e/o spazi).",
                    },
                  })}
                />
                <label className="form__label" htmlFor="surname">
                  Cognome *
                </label>
              </div>
              {errors.surname ? (
                <span className="d-block form__error">{errors.surname.message}</span>
              ) : null}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="form__group">
              <div className="form__wrap-input-text">
                <input
                  type="email"
                  className="form__input-text"
                  name="email"
                  id="email"
                  placeholder=" "
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Specificare un indirizzo email.",
                    },
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Specificare un indirizzo email valido.",
                    },
                  })}
                />
                <label className="form__label" htmlFor="email">
                  Email *
                </label>
              </div>
              <div className="form__note">Il report sarà inviato a questo indirizzo</div>
              {errors.email ? (
                <span className="d-block form__error">{errors.email.message}</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="form__navigation">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <button type="submit" className="btn-link btn-link--small btn-link--next">
                  <span className="btn-link__circle"></span>
                  <span className="btn-link__text">Prosegui</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AcquistaReportStep1;
